<template lang="pug">
#upload-list.flex.column
  .header.flex.align-center.justify-space-between.p-20
    el-button(@click="showSyncList") 查看同步狀態
    .uploader.flex-1.flex.align-center.justify-center.gap-12
      .file-name.flex.align-center
        template(v-if="file === undefined") 請選擇上傳檔案
        template(v-else) {{ file.name }}
        input.hidden(type="file", ref="fileInput", @change="fileChange")
      el-button(type="primary", @click="chooseFile") 選擇檔案
      template(v-if="file !== undefined")
        el-button(type="primary", @click="upload") 上傳
    el-button(@click="logout") 登出
  el-table.mr-20.ml-20(:data="items")
    el-table-column(label="檔案名稱", prop="filename")
    el-table-column(label="檔案大小")
      template(slot-scope="scope")
        span {{ $showSize(scope.row.file_size) }}
    el-table-column(label="上傳時間")
      template(slot-scope="scope")
        span {{ $timestampToDateTime(scope.row.upload_time )}}
    el-table-column(label="資料筆數", prop="record_count")
    el-table-column(label="失敗筆數")
      template(slot-scope="scope")
        span(v-if="scope.row.status === ITEM_STATUS_PROCESSING") ---
        span(v-else) {{ scope.row.record_failed }}
    el-table-column(label="處理狀態")
      template(slot-scope="scope")
        span
          template(v-if="scope.row.status === ITEM_STATUS_PROCESSING") 處理中
          template(v-else-if="scope.row.status === ITEM_STATUS_DONE") 處理完成
          template(v-else) {{ scope.row.status }}
    el-table-column(label="操作")
      template(slot-scope="scope")
        .cell.flex.column.gap-4.align-start
          el-button(
            type="warning", v-if="scope.row.status === ITEM_STATUS_PROCESSING",
            @click="reloadItem(scope.row)") 更新狀態
          el-button(v-if="scope.row.record_failed > 0"
            type="info", @click="showFail(scope.row)") 下載失敗內容
          el-button(v-if="debug"
            type="info", @click="popDebug(scope.row)") 顯示 API 紀錄
  el-dialog(title="同步狀態", :visible.sync="syncTableVisible")
    .operator
      el-button(size="mini", @click="prevMonth") <
      span {{ $timestampToMonth(syncShowMonth) }}
      el-button(size="mini", @click="nextMonth") >
    el-table(:data="syncList")
      el-table-column(property="day", label="日期", width="100px")
      el-table-column(property="fileName", label="檔案名稱", width="210px")
      el-table-column(property="syncTime", label="同步時間", width="160px")
      el-table-column(label="狀態", width="120px")
        template(slot-scope="scope")
          span(v-if="scope.row.status === 'success'") 成功
          .flex.align-center.gap-4(v-else)
            span 失敗
            el-button(size="mini", @click="retrySync(scope.row, idx)") 重試
      el-table-column(label="失敗訊息")
        template(slot-scope="scope")
          span {{ getErrorMessage(scope.row.error) }}
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import {
  ITEM_STATUS_PROCESSING,
  ITEM_STATUS_DONE,
} from '@/constant';
import {
  uploadItem,
  getList,
  getItemDetail,
  getItemFailList,
  getSyncList,
  retrySync,
  setMock,
} from '@/api';

const csvColumns = [
  { key: 'npsWsName', name: 'Workshop Name Short' },
  { key: 'npsDriverName', name: 'Driver Name' },
  { key: 'npsCarNum', name: 'License Plate Number' },
  { key: 'npsPhoneNum', name: 'Mobile phone' },
  { key: 'service_date', name: 'Service Date' },
  { key: 'respondentID', name: 'RespondentID' },
  { key: 'error', name: 'API Return message' },
  { key: 'request', name: 'Raw Request' },
  { key: 'response', name: 'Raw Response' },
];

const errMsgMap = {
  FileNotFoundError: '當日檔案不存在',
  PermissionError: '權限錯誤',
  Error: '未知原因',
};

export default {
  data() {
    const now = new Date();
    now.setDate(1);
    now.setHours(0, 0, 0, 0);
    return {
      file: undefined,
      debug: false,

      items: [],
      ITEM_STATUS_PROCESSING,
      ITEM_STATUS_DONE,

      syncTableVisible: false,
      syncList: [],
      syncShowMonth: now,
    };
  },
  computed: {
    ...mapGetters(['isLogin', 'accessToken']),
  },
  methods: {
    ...mapMutations(['setToken']),
    getErrorMessage(key) {
      return errMsgMap[key] || key;
    },
    retrySync(data, idx) {
      this.$execWithLoading(async () => {
        const rsp = await retrySync(data.year, data.month, data.date);
        this.syncList[idx] = this.parseAPIFile(rsp);
        if (rsp.status === 'fail') {
          this.$showWarn(`重試失敗\n${this.getErrorMessage(rsp.error)}`, '');
        } else {
          this.$showSuccess('重試成功');
        }
        this.$forceUpdate();
      }, (e) => {
        this.$showAxiosException('重試失敗', e);
      });
    },
    prevMonth() {
      const t = new Date(this.syncShowMonth);
      t.setMonth(t.getMonth() - 1);
      this.syncShowMonth = t;
      this.loadSyncData();
    },
    nextMonth() {
      const t = new Date(this.syncShowMonth);
      t.setMonth(t.getMonth() + 1);
      this.syncShowMonth = t;
      this.loadSyncData();
    },
    parseAPIFile(d) {
      return {
        ...d,
        syncTime: this.$timestampToDateTime(new Date(d.sync_time)),
        fileName: d.file_name,
        day: this.$timestampToDate(new Date(`${d.year}/${d.month}/${d.date}`)),
      };
    },
    async loadSyncData() {
      return this.$execWithLoading(async () => {
        const list = await getSyncList(
          this.syncShowMonth.getFullYear(), this.syncShowMonth.getMonth() + 1,
        );
        this.syncList = list.map((l) => this.parseAPIFile(l));
      }, (e) => {
        this.syncList = [];
        this.$showAxiosException('讀取同步列表失敗', e);
      });
    },
    showSyncList() {
      const now = new Date();
      now.setDate(1);
      now.setHours(0, 0, 0, 0);
      this.syncShowMonth = now;
      this.syncTableVisible = true;
      this.loadSyncData();
    },
    chooseFile() {
      this.$refs.fileInput.click();
    },
    fileChange() {
      const [file] = this.$refs.fileInput.files;
      this.file = file;
    },
    async upload() {
      return this.$execWithLoading(async () => {
        await uploadItem(this.file);
        this.$refs.fileInput.value = '';
        this.file = undefined;
        this.$showSuccess('上傳檔案成功');
        await this.loadListWithLoading();
      }, (e) => {
        this.$showAxiosException('上傳檔案失敗', e);
      });
    },
    async loadListWithLoading() {
      const rsp = await getList();
      let itemList = rsp.map((item) => ({
        ...item,
        upload_time: new Date(`${item.upload_time}+0000`),
      }));

      itemList = itemList.sort(
        (a, b) => (a.upload_time.getTime() > b.upload_time.getTime() ? -1 : 1),
      );
      this.items = itemList;
    },
    async loadList() {
      return this.$execWithLoading(async () => {
        await this.loadListWithLoading();
      }, (e) => {
        this.$showAxiosException('讀取上傳列表失敗', e);
      });
    },
    async reloadItem(item) {
      return this.$execWithLoading(async () => {
        const rsp = await getItemDetail(item.uid);
        // eslint-disable-next-line no-param-reassign
        item.status = rsp.status;
        // eslint-disable-next-line no-param-reassign
        item.record_failed = rsp.record_failed;
        this.$forceUpdate();
      }, (e) => {
        console.log(e);
        this.$showAxiosException('無法更新狀態，請重新整理頁面', e);
      });
    },
    async showFail(item) {
      return this.$execWithLoading(async () => {
        const rsp = await getItemFailList(item.uid);
        const records = rsp.map((r) => this.convertRecordToRow(r));
        const csvContent = this.recordsToCSV(records);
        const fileName = `fail-${parseInt(new Date().getTime() / 1000, 10)}.csv`;
        this.$downloadCSVFile(csvContent, fileName);
      }, (e) => {
        console.log(e);
        this.$showAxiosException('無法抓取失敗資料', e);
      });
    },
    async popDebug(item) {
      const rsp = await getItemFailList(item.uid);
      alert(JSON.stringify(rsp));
    },
    convertRecordToRow(record) {
      /*  info
            npsCarNum:"ABC-5671"
            npsDriverName:"Kantar Dorothy1"
            npsPhoneNum:"0911222331"
            npsWsName:"花蓮"
            service_date:20241009
          resp
            message
      */
      return {
        npsWsName: record.info.npsWsName,
        npsDriverName: record.info.npsDriverName,
        npsCarNum: record.info.npsCarNum,
        npsPhoneNum: record.info.npsPhoneNum,
        respondentID: record.info.respondentID || '',
        service_date: record.info.service_date || '',
        error: record.resp.message,
        request: JSON.stringify(record.info),
        response: JSON.stringify(record.resp),
      };
    },
    recordsToCSV(records) {
      const titleLine = csvColumns.map((col) => col.name).join(',');
      const datas = records.map((record) => csvColumns.map((col) => `"${(record[col.key] || '').replace(/"/g, '""') || ''}"`).join(',')).join('\n');
      return `${titleLine}\n${datas}\n`;
    },
    logout() {
      this.setToken('');
      this.$router.push('/login');
    },
    checkDebug() {
      const param = new URLSearchParams(document.location.search);
      if (param.get('debug')) {
        this.debug = true;
      }
      if (param.get('mock')) {
        setMock(true);
      }
    },
  },
  mounted() {
    if (!this.isLogin) {
      this.$router.push('/login');
      return;
    }
    this.checkDebug();
    this.loadList();
  },
};
</script>

<style lang="scss" scoped>
#upload-list {
  overflow: hidden;
}
.header {
  background: rgb(10, 66, 106);
  flex: 0 0 40px;
  color: white;
}
.content {
  overflow: auto;
  overflow: overlay;
}
.el-button {
  margin-left: 0 !important;
}
.operator {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
#upload-list {
  // hack
  .el-table {
    display: flex;
    flex-direction: column;

    // handle scroll
    .el-table__header-wrapper {
      flex: 0 0 auto;
    }
    .el-table__body-wrapper {
      flex: 1;
      overflow: overlay;
    }
  }
}
</style>
